import React, { FC, ReactElement, useMemo } from 'react';
import { Container, Row } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import { ShippingAddressesProps } from 'components/shipping-addresses/shipping-addresses.props';
import './shipping-addresses.style.scss';
import AddressCard from 'components/shipping-addresses/address-card/address-card.component';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileObjectAddressPayload } from 'state/account/account.services';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import AddAddressForm, {
    AddressFormSchema,
    AddressVerificationFailureModalContent
} from 'components/add-address-form/AddAddressForm';
import { AddressParts } from 'state/usps/usps.reducers';
import { useAddressVerification } from 'hooks/useAddressVerification';
import {
    accountAddAddressToProfileRoutine,
    accountRemoveAddressFromProfileRoutine,
    accountSetPrimaryAddressRoutine,
    accountUpdateAddressToProfileRoutine
} from 'state/account/account.routines';
import { AddressCardProps } from './address-card/address-card.props';
import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent
} from 'pages/secure/profile/intra-page-items/profile-update-modal.item';
import { accountProfileAddressesSelector } from 'state/account/account.selectors';

const DeleteAddressModalContent = ({
    address,
    translation
}: {
    address: AddressCardProps;
    translation: TFunction<string>;
}) => {
    return (
        <BirdiModalContent
            icon={'alert'}
            title={translation('modals.changeAddressModal.title')}
            body={translation('modals.changeAddressModal.body', {
                address: `<strong>${address.address1}, ${address.city}, ${address.state} ${address.zipcode}</strong>`
            })}
        />
    );
};

const ShippingAddresses: FC<ShippingAddressesProps> = ({
    addressData,
    defaultAddressLabel,
    onChange,
    isProfile,
    showLabels = true,
    buttonVariant = 'primary',
    addNewAddressButtonLabel
}: ShippingAddressesProps): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const { isBusy: isAddAddressBusy, verifyAddress } = useAddressVerification();
    const sortDefaultAddresses = useMemo(() => {
        // Add defaultAddress boolean to first address automatically to be addressed in DRX-115
        return addressData.sort((a, b) => Number(b.defaultAddress) - Number(a.defaultAddress));
    }, [addressData]);

    const handleAddAddressFormCancel = () => {
        dispatch(closeModal({}));
    };

    const handleAddNewAddressClick = () => {
        dispatch(
            openModal({
                showClose: true,
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('modals.addAddressModal.title')}
                        body={
                            <AddAddressForm
                                handleFormCancel={handleAddAddressFormCancel}
                                handleFormSubmit={handleAddAddressFormSubmit}
                                centerFormSubmit={true}
                                isAddressVerifying={isAddAddressBusy}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const handleAddAddressFormSubmit = (values: ProfileObjectAddressPayload) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };
        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                dispatch(
                    accountAddAddressToProfileRoutine.trigger({
                        ...values,
                        zipcode: Zip5,
                        zipcodeFour: Zip4,
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.address')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            }
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
                dispatch(closeModal({}));
            },
            onFailure: () => {
                dispatch(
                    openModal({
                        showClose: true,
                        bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                        ctas: [
                            {
                                label: t('modals.healthConditions.submit'),
                                variant: 'primary',
                                onClick: () => {
                                    dispatch(closeModal({}));
                                    handleAddNewAddressClick(values);
                                }
                            }
                        ]
                    })
                );
            }
        });
    };

    const handleShippingAddressChange = (address: AddressCardProps) => {
        dispatch(
            accountSetPrimaryAddressRoutine({
                ...address,
                onSuccess: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: <UpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateProfile.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: (
                                <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                            ),
                            ctas: [
                                {
                                    label: t('modals.updateProfile.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handleOpenDeleteModal = (address: AddressCardProps) => {
        dispatch(
            openModal({
                showClose: true,
                bodyContent: <DeleteAddressModalContent address={address} translation={t} />,
                ctas: [
                    {
                        label: 'Confirm',
                        variant: 'primary',
                        onClick: () => {
                            dispatch(accountRemoveAddressFromProfileRoutine(address));
                            dispatch(closeModal({}));
                        }
                    }
                ]
            })
        );
    };

    const handleEditAddressClick = (address: AddressCardProps) => {
        const editAddress: AddressFormSchema =
            address.zipcodeFour === ''
                ? { ...address, addressTypeDesc: address.addressType }
                : {
                      ...address,
                      zipcode: `${address.zipcode}-${address.zipcodeFour}`,
                      addressTypeDesc: address.addressType
                  };
        const editAddressIndex = profileAddresses.findIndex(
            (profileAddress) =>
                profileAddress.address1 === address.address1 &&
                profileAddress.address2 === address.address2 &&
                profileAddress.city === address.city &&
                profileAddress.state === address.state &&
                profileAddress.zipcode === address.zipcode + '' &&
                profileAddress.zipcodeFour === address.zipcodeFour + ''
        );

        dispatch(
            openModal({
                showClose: true,
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('modals.editAddressModal.title')}
                        body={
                            <AddAddressForm
                                defaultValues={editAddress}
                                handleFormCancel={() => {}}
                                handleFormSubmit={(values) => {
                                    handleEditAddressSubmit(values, editAddressIndex);
                                }}
                                centerFormSubmit={true}
                                showCancel={false}
                                isAddressVerifying={isAddAddressBusy}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const handleEditAddressSubmit = (values: ProfileObjectAddressPayload, editAddressIndex: Number) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };

        verifyAddress({
            address,
            onSuccess: () => {
                const Zip5 = values.zipcode.length > 5 ? values.zipcode.slice(0, 5) : values.zipcode;
                const Zip4 = values.zipcode.length > 5 ? values.zipcode.slice(-4) : '';
                const address = { ...values, zipcode: Zip5, zipcodeFour: Zip4 };
                dispatch(
                    accountUpdateAddressToProfileRoutine({
                        index: editAddressIndex,
                        address,
                        onSuccess: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <UpdateProfileModalContent area={t('modals.updateProfile.areas.address')} />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            }
                                        }
                                    ]
                                })
                            );
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.address')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            }
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
            },
            onFailure: () => {
                dispatch(
                    openModal({
                        showClose: true,
                        bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                        ctas: [
                            {
                                label: t('modals.addressVerificationFailure.submit'),
                                variant: 'primary',
                                onClick: () => {
                                    dispatch(closeModal({}));
                                }
                            }
                        ]
                    })
                );
            }
        });
    };

    return (
        <>
            <Container fluid className="mt-4">
                <Row xs={1} md={1} lg={2} xl={2} className="gy-3 d-flex">
                    {sortDefaultAddresses.map((value, index) => {
                        return (
                            <AddressCard
                                key={index}
                                index={index}
                                defaultAddress={value.defaultAddress}
                                address1={value.address1}
                                address2={value.address2}
                                city={value.city}
                                state={value.state}
                                zipcode={value.zipcode}
                                zipcodeFour={value.zipcodeFour}
                                isChecked={value.defaultAddress}
                                onChange={() => {
                                    if (onChange) {
                                        onChange(value);
                                    } else {
                                        handleShippingAddressChange(value);
                                    }
                                }}
                                openDeleteModal={() => handleOpenDeleteModal(value)}
                                openEdit={() => handleEditAddressClick(value)}
                                defaultAddressLabel={defaultAddressLabel}
                                addressType={value.addressType}
                                isProfile={isProfile}
                                showLabel={showLabels}
                            />
                        );
                    })}
                </Row>
            </Container>
            {addNewAddressButtonLabel && (
                <Container fluid>
                    <Button
                        className={`${buttonVariant === 'primary' ? 'sm-full' : 'p-0'}`}
                        label={addNewAddressButtonLabel}
                        type="button"
                        variant={buttonVariant}
                        onClick={handleAddNewAddressClick}
                        dataGAFormName="addresses"
                        chevron={`${buttonVariant === 'text-blue' ? 'right' : ''}`}
                    />
                </Container>
            )}
        </>
    );
};

export default ShippingAddresses;
