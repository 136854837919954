import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';

//  state
import { accountCreditCardsSelector, accountProfileAddressesSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    accountFetchHealthConditionsRoutine,
    accountFetchProfileRoutine,
    accountGetAllCreditCardsRoutine
} from 'state/account/account.routines';

//  price
import { formatPrice } from 'schema/price.schema';

// Cart
import {
    cartItemsSelector,
    cartOrderBillShipSelector,
    cartSelector,
    cartOrderBillShipMethodSelector
} from 'state/cart/cart.selectors';
import {
    getCartRoutine,
    cartCompleteOrderRoutine,
    cartUpdatePaymentRoutine,
    cartUpdateShippingRoutine
} from 'state/cart/cart.routines';

//  hooks
import { useHealthConditions } from 'hooks/useHealthConditions';

//  health profile
import {
    AllergiesModalContent,
    HealthConditionsModalContent
} from 'components/health-profile/health-profile.component';
import { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import { HealthConditionsWarningModalContent } from 'pages/secure/profile/health-profile';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';

//  workflow
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

//  shipping address
import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';
import ShippingAddresses from 'components/shipping-addresses/shipping-addresses.component';

//  payment
import PaymentMethods from 'components/payment-methods/payment-methods.components';
import { medicineCabinetPrescriptionsSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

//  modal
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

// analytics
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

//  misc ui-kit
import Button from 'ui-kit/button/button';
import BirdiAccordion from 'ui-kit/accordion/accordion';
import LockIcon from 'ui-kit/icons/lock-icon/lock-icon';

import './review.style.scss';
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';
import UpdateCartModalContent, { FailureUpdateCartModalContent } from '../intra-page-items/cart-update-modal-item';
import { EXPEDITED_SHIPPING_ID, EXPEDITED_SHIPPING_COST } from '../index';

const PrescriptionOrderSuccessModalContent = ({
    ctaLabel,
    image,
    mobileImage,
    title,
    text,
    onCtaClick
}: {
    ctaLabel: string;
    image: FluidObject;
    mobileImage: FluidObject;
    title: string;
    text: string;
    onCtaClick: () => void;
}) => {
    return (
        <BirdiModalContent
            icon="none"
            body={
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} className="d-lg-none">
                            <GatsbyImage
                                className="order-success-modal-image mobile"
                                image={getImage(mobileImage)}
                                alt={''}
                            />
                        </Col>
                        <Col lg={6} className="d-none d-lg-block">
                            <GatsbyImage className="order-success-modal-image" image={getImage(image)} alt={''} />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} className="d-flex flex-column justify-content-center">
                            <div className="order-success-modal-content d-flex flex-column justify-content-center align-items-center align-items-lg-start">
                                <h1 className="text-center">{title}</h1>
                                <div className="spacer" />
                                <div className="text-center text-lg-left mb-4">{text}</div>
                                <Button type="button" label={ctaLabel} onClick={onCtaClick} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            }
        />
    );
};

const PrescriptionContentSectionHeader = ({
    label,
    text
}: {
    label: string;
    text?: string | ReactElement;
}): ReactElement => {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <div className="cart-review-workflow-layout-content-section-header mt-5">
                        <div className="blue-half-pill" style={{ height: '0.8rem', width: '0.8rem' }} />
                        <div className="cart-review-workflow-layout-content-section-header-title">{label}</div>
                    </div>
                </Col>
            </Row>
            {text && (
                <Row>
                    <Col className="mt-4">{text}</Col>
                </Row>
            )}
        </Container>
    );
};

const PrescriptionInformation = ({
    t,
    cartItems,
    prescriptions
}: {
    t: TFunction<string>;
    cartItems: any;
    prescriptions: any;
}) => {
    return (
        <>
            <Container className="cart-review" fluid>
                <div className="cart-review--border-bottom">
                    {cartItems?.map((item: any) => {
                        const currentPrescription = prescriptions.find((obj: any) => {
                            return obj.rxNumber === item.rxNumber;
                        });
                        return (
                            <Row key={item.rxNumber} className="mt-3 mb-2">
                                <Col sm={12} lg={9}>
                                    <h6 className="cart-review--header">
                                        {currentPrescription?.dispensedProductName}
                                        {!item.messageStatus ? <sup>*</sup> : ''}
                                    </h6>
                                </Col>
                                <Col sm={12} lg={3}>
                                    <div className="d-flex justify-content-end cart-review--cost">
                                        <div className="d-flex align-items-center">
                                            {!item.messageStatus ? '' : formatPrice(item.patientCopay)}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}
                </div>
            </Container>
        </>
    );
};

const CartTotal = ({
    t,
    hasUnknownPrice,
    currentPrice,
    shippingPrice,
    initialPrice
}: {
    t: TFunction<string>;
    hasUnknownPrice: boolean;
    currentPrice?: string;
    shippingPrice?: string;
    initialPrice?: string;
}) => {
    return (
        <Container fluid className="cart-total d-flex flex-column mt-4 mb-4">
            <Row>
                <Col sm={12} lg={12}>
                    <div className="cart-total--subTotal d-flex justify-content-between">
                        <h6 className="cart-total--title">
                            {t('pages.cart.subTotal')}
                            {hasUnknownPrice ? <sup>*</sup> : ''}:
                        </h6>
                        <h6>{formatPrice(initialPrice)}</h6>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--shipping d-flex justify-content-between mt-3">
                        <h6 className="cart-total--title">{t('pages.cart.shipping')}</h6>
                        <h6>{formatPrice(shippingPrice)}</h6>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="cart-total--total d-flex justify-content-between mt-2 pt-3">
                        <h6 className="cart-total--title">
                            {t('pages.cart.total')}
                            {hasUnknownPrice ? <sup>*</sup> : ''}:
                        </h6>
                        <h6>{formatPrice(currentPrice)}</h6>
                    </div>
                </Col>
            </Row>
            {hasUnknownPrice && (
                <Row className="mt-4">
                    <Col>
                        <small>
                            <sup>*</sup> {t('pages.cart.totalDisclaimer')}
                        </small>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

const ReviewOrder = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const prescriptionsObject = useSelector(medicineCabinetPrescriptionsSelector);
    const cartItemsObject = useSelector(cartItemsSelector);
    const cartObject = useSelector(cartSelector);
    const initialShippingId = useSelector(cartOrderBillShipMethodSelector);

    const {
        existingAllergies,
        existingConditions,
        userHasNotSubmittedAllergies,
        userHasNotSubmittedConditions
    } = useHealthConditions();

    const initialOrderPrice = cartObject?.orderTotal;
    const [currentPrice, setCurrentPrice] = useState(initialOrderPrice);
    const [currentShippingPrice, setCurrentShippingPrice] = useState(0);
    const [errorStatus, setErrorStatus] = useState(false);

    useEffect(() => {
        if (initialShippingId === EXPEDITED_SHIPPING_ID) {
            setCurrentShippingPrice(EXPEDITED_SHIPPING_COST);
        } else {
            setCurrentShippingPrice(0);
        }
    }, [initialShippingId]);

    useEffect(() => {
        if (initialShippingId === EXPEDITED_SHIPPING_ID) {
            setCurrentPrice(initialOrderPrice + currentShippingPrice);
        } else {
            setCurrentPrice(initialOrderPrice);
        }
    }, [initialOrderPrice, currentShippingPrice]);

    useEffect(() => {
        dispatch(accountGetAllCreditCardsRoutine.trigger());
    }, []);
    useEffect(() => {
        if (cartObject === undefined) {
            dispatch(getCartRoutine.trigger());
        }
        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: true }));
    }, []);
    const allPaymentData = useSelector(accountCreditCardsSelector);

    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const orderBillShip = useSelector(cartOrderBillShipSelector);
    const addresses: AddressCardProps[] = profileAddresses.map((address) => {
        const cartCurrentShippingSeqNum = orderBillShip?.patientShipAddressSeq;
        const isCurrentCartShippingAddress = address.addressSeqNum === cartCurrentShippingSeqNum;
        return {
            defaultAddress: isCurrentCartShippingAddress,
            address1: address.address1,
            address2: address.address2,
            city: address.city,
            country: address.country,
            state: address.state,
            zipcode: address.zipcode,
            zipcodeFour: address.zipcodeFour,
            defaultAddressLabel: t('shipping.shipToThisAddressLabel'),
            addressType: address.addressTypeDesc,
            isProfile: false
        };
    });

    const hasUnknownPrice = cartItemsObject?.length
        ? cartItemsObject.findIndex((item: any) => {
              return !item.messageStatus;
          }) > -1
        : false;
    const orderPaymentData = allPaymentData?.map((paymentMethod) => {
        const cartCurrentPaymentSeqNum = orderBillShip?.paymentCardSeqNum;
        const isCurrentCartPaymentMethod = paymentMethod.cardSeqNum === cartCurrentPaymentSeqNum;
        return {
            ...paymentMethod,
            defaultCard: cartCurrentPaymentSeqNum ? isCurrentCartPaymentMethod : paymentMethod.defaultCard
        };
    });

    const imageData = useStaticQuery(graphql`
        query {
            backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            successModalPillImage: file(relativePath: { eq: "assets/images/order-success-pill.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
            successModalPillImageMobile: file(relativePath: { eq: "assets/images/order-success-pill.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    const handleUpdateConditionsClick = ({ action, update }: HealthProfileBubbleUpdateEvent) => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                ctas: [
                    {
                        label: t('modals.healthConditions.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                            dispatch(action(update));
                        }
                    }
                ]
            })
        );
    };
    const handleToggleHealthConditionsClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <HealthConditionsModalContent
                        title={t('modals.prescription.addHealthCondition.title')}
                        subTitle={t('modals.prescription.addHealthCondition.subTitle', {
                            phoneNumber: t('modals.healthConditions.phoneNumber')
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        submitLabel={t('modals.prescription.addHealthCondition.submit')}
                    />
                ),
                ctas: []
            })
        );
    };
    const handleToggleAllergiesClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <AllergiesModalContent
                        title={t('modals.prescription.addAllergy.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: t('modals.healthConditions.phoneNumber')
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        freeformConditionsLabel={t('components.healthConditions.labels.freeformAllergiesLabel')}
                        submitLabel={t('modals.prescription.addAllergy.submit')}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleShipToAddressClick = (address: AddressCardProps) => {
        const editAddress = profileAddresses.find(
            (location) =>
                location.address1 === address.address1 &&
                location.address2 === address.address2 &&
                location.city === address.city &&
                location.state === address.state &&
                location.zipcode === address.zipcode + ''
        );
        const updatedAddress = {
            ...orderBillShip,
            patientBillAddressSeq: editAddress?.addressSeqNum,
            patientShipAddressSeq: editAddress?.addressSeqNum
        };
        dispatch(
            cartUpdateShippingRoutine.trigger({
                ...updatedAddress,
                onSuccess: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <UpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handleUpdateOrderPayment = (index: number, endingDigits: number) => {
        const updateItem = allPaymentData?.find((payment) => payment.secureCardNumber.slice(-4) === endingDigits);
        const updatedPayment = {
            ...orderBillShip,
            paymentCardSeqNum: updateItem?.cardSeqNum
        };
        dispatch(
            cartUpdatePaymentRoutine.trigger({
                ...updatedPayment,
                onSuccess: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <UpdateCartModalContent area={t('modals.updateCart.areas.payment')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.payment')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const handleSubmitOrderClick = () => {
        setErrorStatus(false);
        dispatch(
            cartCompleteOrderRoutine({
                onSuccess: () => {
                    const orderInvoiceNum = cartObject?.orderHeader?.orderInvoiceNumber;
                    TrackCheckoutStep({
                        stepName: 'purchase',
                        step: '1',
                        cart: cartObject,
                        prescriptions: prescriptionsObject,
                        t: t,
                        shippingCost: cartObject?.orderHeader?.orderHighPriority ? EXPEDITED_SHIPPING_COST : 0
                    });
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            onClose: () => {
                                navigate('/secure/medicine-cabinet');
                            },
                            contentClassName: 'prescription-order-success-modal',
                            bodyContent: (
                                <PrescriptionOrderSuccessModalContent
                                    title={t('modals.orderSuccess.title')}
                                    text={t('modals.orderSuccess.text', { orderNumber: orderInvoiceNum })}
                                    ctaLabel={t('modals.orderSuccess.ctaLabel')}
                                    image={imageData.successModalPillImage}
                                    mobileImage={imageData.successModalPillImageMobile}
                                    onCtaClick={() => {
                                        dispatch(closeModal({}));
                                        navigate('/secure/medicine-cabinet');
                                    }}
                                />
                            ),
                            ctas: []
                        })
                    );
                    dispatch(getCartRoutine.trigger());
                },
                onFailure: () => {
                    dispatch(getCartRoutine.trigger());
                    setErrorStatus(true);
                },
                orderTotal: currentPrice
            })
        );
    };

    useEffect(() => {
        dispatch(accountFetchProfileRoutine.trigger());
        dispatch(accountFetchHealthConditionsRoutine.trigger());
        TrackCheckoutStep({
            stepName: 'checkout',
            step: '2',
            cart: cartObject,
            prescriptions: prescriptionsObject,
            t: t,
            shippingCost: cartObject?.orderHeader?.orderHighPriority ? `${EXPEDITED_SHIPPING_COST}` : '0'
        });
    }, [cartObject]);

    const HealthProfileHeaderText = () => {
        return (
            <div>
                {t('pages.reviewOrder.healthProfileConfirmation')} <strong>1-877-668-4987</strong>.
            </div>
        );
    };

    return (
        <WorkflowLayout
            className="cart-review-workflow"
            backgroundImage={imageData.backgroundImage}
            metaData={{ nodeTitle: t('pages.reviewOrder.title') }}
        >
            <WorkflowLayoutFormWrapper
                eyebrowText={t('pages.reviewOrder.eyebrowText')}
                title={t('pages.reviewOrder.title')}
                className="cart-review-workflow-layout-form-wrapper"
                icon={<LockIcon className={'header-icon-container'} />}
            >
                <Col>
                    <PrescriptionContentSectionHeader label={t('pages.reviewOrder.orderSummary')} />
                    <PrescriptionInformation t={t} cartItems={cartItemsObject} prescriptions={prescriptionsObject} />
                    <CartTotal
                        t={t}
                        hasUnknownPrice={hasUnknownPrice}
                        currentPrice={currentPrice}
                        shippingPrice={currentShippingPrice}
                        initialPrice={initialOrderPrice}
                    />
                    <PrescriptionContentSectionHeader
                        label={t('pages.profile.healthProfile.labels.healthProfile')}
                        text={<HealthProfileHeaderText />}
                    />
                    <Container fluid className="mt-5">
                        <Row>
                            <Col>
                                <BirdiAccordion.Toggle
                                    title={t('accordions.healthConditions.title')}
                                    toggleText={t('accordions.healthConditions.toggleText')}
                                    includeTitleIcon={false}
                                    onToggle={handleToggleHealthConditionsClick}
                                    alwaysOpenContent={<HealthConditionPills conditions={existingConditions} />}
                                />
                            </Col>
                        </Row>
                        {userHasNotSubmittedConditions && (
                            <Row>
                                <Col>
                                    <div className="error">{t('pages.reviewOrder.errors.conditionsRequired')}</div>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col>
                                <BirdiAccordion.Spacer />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <BirdiAccordion.Toggle
                                    title={t('accordions.allergies.title')}
                                    toggleText={t('accordions.allergies.toggleText')}
                                    includeTitleIcon={false}
                                    onToggle={handleToggleAllergiesClick}
                                    alwaysOpenContent={<HealthConditionPills conditions={existingAllergies} />}
                                />
                            </Col>
                        </Row>
                        {userHasNotSubmittedAllergies && (
                            <Row>
                                <Col>
                                    <div className="error">{t('pages.reviewOrder.errors.allergiesRequired')}</div>
                                </Col>
                            </Row>
                        )}
                    </Container>
                    <PrescriptionContentSectionHeader label={t('pages.profile.healthProfile.labels.shippingAddress')} />
                    <ShippingAddresses
                        addressData={addresses}
                        onChange={handleShipToAddressClick}
                        defaultAddressLabel={t('shipping.shipToThisAddressLabel')}
                        isProfile={false}
                        showLabels={false}
                        addNewAddressButtonLabel={t('pages.reviewOrder.addAddress')}
                        buttonVariant={'text-blue'}
                    />
                    <PrescriptionContentSectionHeader
                        label={t('pages.profile.healthProfile.labels.paymentInformation')}
                    />
                    <PaymentMethods
                        paymentData={orderPaymentData ? orderPaymentData : []}
                        onChange={handleUpdateOrderPayment}
                        addNewPaymentButtonLabel={t('pages.reviewOrder.addPayment')}
                        paymentRequiredMessage={t('pages.reviewOrder.paymentMethodRequired')}
                        buttonVariant="text-blue"
                        dataGAFormName="Payments"
                        isProfile={false}
                        showLabels={false}
                    />
                    <Row className="pt-5">
                        <Col>
                            {!!errorStatus && (
                                <div className="text has-errors">
                                    <div className="text-errors text-right">
                                        {t('pages.reviewOrder.errors.orderError')}
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row className="pt-1 justify-content-center justify-content-md-end">
                        <Col
                            xs={{ span: 12, order: 'first' }}
                            md={{ span: 'auto', order: '2' }}
                            className="d-flex flex-column justify-content-md-start align-items-center align-items-md-end"
                        >
                            <Button
                                type="button"
                                className="cart-submit-button"
                                label={t('pages.reviewOrder.submit')}
                                onClick={handleSubmitOrderClick}
                                dataGAFormName="OrderPrescription"
                                dataGAFormStepName="Checkout"
                                disabled={
                                    orderBillShip?.paymentCardSeqNum === null ||
                                    orderBillShip?.patientBillAddressSeq === null ||
                                    orderBillShip?.patientShipAddressSeq === null ||
                                    userHasNotSubmittedConditions ||
                                    userHasNotSubmittedAllergies
                                }
                            />
                        </Col>
                        <Col
                            className="d-flex justify-content-center justify-content-md-end"
                            xs={{ span: 12, order: 'last' }}
                            md={{ span: 'auto', order: 'first' }}
                        >
                            <Button
                                type="button"
                                variant="text"
                                className="mt-3 mt-md-0 p-0"
                                label={t('pages.reviewOrder.backToCart')}
                                onClick={() => {
                                    navigate('/secure/cart');
                                }}
                                dataGAFormName="OrderPrescription"
                                dataGAFormStepName="Checkout"
                            />
                        </Col>
                        <Col
                            xs={{ span: 10, order: '2' }}
                            md={{ span: 12, order: 'last' }}
                            className="d-flex justify-content-center justify-content-md-end mt-4"
                        >
                            <h6 className="font-weight-lighter footer-note">{t('pages.reviewOrder.note')}</h6>
                        </Col>
                    </Row>
                </Col>
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default ReviewOrder;
