import React, { FC, ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import './payment-card.style.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { PaymentCardProps } from './payment-card.props';

const PaymentCard: FC<PaymentCardProps> = ({
    cardType,
    endingDigits,
    cardHolder,
    expiryMonth,
    expiryYear,
    defaultCard,
    openDeleteModal,
    isChecked,
    index,
    onChange,
    isProfile,
    showLabel = true
}: PaymentCardProps): ReactElement => {
    const { t } = useTranslation();
    const formattedExpiryMonth = parseInt(expiryMonth) < 10 ? `0${expiryMonth}` : expiryMonth;
    const paymentType = defaultCard ? 'primaryPayment' : 'secondaryPayment';

    return (
        <Col className="mb-4 flex-fill">
            <div className="payment-card d-flex flex-column border rounded rounded-2 border-sea-foam p-4 h-100">
                <Row className="payment-card--content h-100">
                    <Col xs={12} className={'align-self-start'}>
                        <div className="d-flex justify-content-between mb-2">
                            {showLabel && (
                                <p className="payment-card__type align-self-start">
                                    <small>{t(`pages.profile.payment.${paymentType}`)}</small>
                                </p>
                            )}
                            {!defaultCard && isProfile && (
                                <Button
                                    variant="text"
                                    type="button"
                                    onClick={() => openDeleteModal(index, endingDigits)}
                                    label={t('shipping.removeAddress')}
                                />
                            )}
                        </div>
                        <h6 className="hero-eyebrow-text">
                            <span className="text-capitalize d-inline-block card-type">{cardType}</span>
                            {t('pages.profile.payment.cardNum', {
                                cardNumber: endingDigits
                            })}
                        </h6>
                        <h6 className="hero-eyebrow-text">{cardHolder}</h6>
                        <h6 className="hero-eyebrow-text">
                            {t('pages.profile.payment.expiry', {
                                month: formattedExpiryMonth,
                                year: expiryYear
                            })}
                        </h6>
                    </Col>
                    <Col xs={12} className={'align-self-end'}>
                        <section className="payment-card--default-payment mt-4">
                            <label className="default-payment d-flex mb-0">
                                <input
                                    type="radio"
                                    name="payment-option"
                                    id={`payment-${index}`}
                                    data-input-type={`profile-payment-option`}
                                    onChange={(e) => {
                                        onChange(index);
                                        e.currentTarget.blur();
                                    }}
                                    checked={isChecked || false}
                                />
                                {isProfile && <span>{t('pages.profile.payment.labels.primaryPaymentMethod')}</span>}
                                {!isProfile && <span>{t('pages.profile.payment.labels.useThisPayment')}</span>}
                            </label>
                        </section>
                    </Col>
                </Row>
            </div>
        </Col>
    );
};

export default PaymentCard;
