import React, { FC, ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { PaymentMethodsProps } from './payment-methods.props';
import PaymentCard from './payment-card/payment-card.component';
import { useDispatch } from 'react-redux';
import {
    accountGetAllCreditCardsRoutine,
    accountRemoveCreditCardRoutine,
    accountUpdateCreditCardRoutine
} from 'state/account/account.routines';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent
} from 'pages/secure/profile/intra-page-items/profile-update-modal.item';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import Button from 'ui-kit/button/button';
import PaymentForm from 'pages/secure/profile/intra-page-items/payment.item';

const PaymentMethods: FC<PaymentMethodsProps> = ({
    paymentData,
    openDeleteModal,
    onChange,
    isProfile,
    buttonVariant = 'primary',
    addNewPaymentButtonLabel,
    paymentRequiredMessage,
    showLabels = true
}: PaymentMethodsProps): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleAddPaymentFormCancel = () => {
        dispatch(closeModal({}));
    };

    const handleAddNewPaymentClick = () => {
        dispatch(
            openModal({
                showClose: true,
                contentClassName: 'prescription-add-payment-modal',
                bodyContent: (
                    <BirdiModalContent
                        icon={'none'}
                        title={t('modals.addPaymentModal.title')}
                        body={
                            <PaymentForm
                                centerFormSubmit={true}
                                isInModal={true}
                                onCancel={handleAddPaymentFormCancel}
                            />
                        }
                    />
                ),
                ctas: []
            })
        );
    };

    const handleDeletePaymentClick = (index: number, endingDigits: number) => {
        const paymentMethod = t('pages.profile.payment.cardTypeAndNum', {
            cardType: paymentData[index].cardType,
            cardNumber: endingDigits
        });
        const removePaymentModalContent = (
            <BirdiModalContent
                icon={'alert'}
                title={t('modals.removePaymentModal.title')}
                body={t('modals.removePaymentModal.body', {
                    paymentMethod: `<strong>${paymentMethod}</strong>`
                })}
            />
        );
        dispatch(
            openModal({
                showClose: true,
                bodyContent: removePaymentModalContent,
                ctas: [
                    {
                        label: 'Continue',
                        variant: 'primary',
                        onClick: () => {
                            handleRemovePayment(endingDigits);
                            dispatch(closeModal({}));
                        }
                    },
                    {
                        label: 'Cancel',
                        variant: 'ghost',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ]
            })
        );
    };

    const handleRemovePayment = (endingDigits: number) => {
        const removalItem = paymentData.find((payment) => payment.secureCardNumber.slice(-4) === endingDigits);
        dispatch(accountRemoveCreditCardRoutine.trigger(removalItem));
    };

    const handleUpdatePrimaryPayment = (index: number, endingDigits: number) => {
        const updateItem = paymentData.find((payment) => payment.secureCardNumber.slice(-4) === endingDigits);
        dispatch(
            accountUpdateCreditCardRoutine.trigger({
                ...updateItem,
                onSuccess: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: <UpdateProfileModalContent area={t('modals.updateProfile.areas.payment')} />,
                            onClose: () => {
                                dispatch(accountGetAllCreditCardsRoutine.trigger());
                            },
                            ctas: [
                                {
                                    label: t('modals.updateProfile.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        dispatch(accountGetAllCreditCardsRoutine.trigger());
                                    }
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            bodyContent: (
                                <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.payment')} />
                            ),
                            ctas: [
                                {
                                    label: t('modals.updateProfile.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    }
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    return (
        <Container fluid className="mt-4">
            <Row xs={1} md={1} lg={2} xl={2} className="gy-3 d-flex">
                {paymentData &&
                    paymentData.map((value, index) => {
                        if (value.cardActive && value.cardIsExpired != true)
                            return (
                                <PaymentCard
                                    cardType={value.cardType}
                                    endingDigits={value.secureCardNumber.slice(-4)}
                                    cardHolder={value.cardName}
                                    expiryMonth={value.cardMonthNum}
                                    expiryYear={value.cardYear.slice(-2)}
                                    defaultCard={value.defaultCard}
                                    openDeleteModal={() =>
                                        handleDeletePaymentClick(index, value.secureCardNumber.slice(-4))
                                    }
                                    isChecked={value.defaultCard}
                                    onChange={() => {
                                        if (onChange) {
                                            onChange(index, value.secureCardNumber.slice(-4));
                                        } else {
                                            handleUpdatePrimaryPayment(index, value.secureCardNumber.slice(-4));
                                        }
                                    }}
                                    key={`payment-card-${index}`}
                                    index={index}
                                    isProfile={isProfile}
                                    showLabel={showLabels}
                                />
                            );
                    })}
            </Row>
            {addNewPaymentButtonLabel && (
                <Row>
                    <Col>
                        <Button
                            className={`${buttonVariant === 'primary' ? 'sm-full' : 'p-0'}`}
                            label={addNewPaymentButtonLabel}
                            type="button"
                            variant={buttonVariant}
                            dataGAFormName="Payments"
                            onClick={() => {
                                handleAddNewPaymentClick();
                            }}
                            chevron={`${buttonVariant === 'text-blue' ? 'right' : ''}`}
                        />
                    </Col>
                </Row>
            )}
            {(!paymentData || paymentData.length === 0) && !isProfile && paymentRequiredMessage && (
                <Row>
                    <Col>
                        <div className="error mt-3">{paymentRequiredMessage}</div>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default PaymentMethods;
