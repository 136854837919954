import React, { FC, ReactElement } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'ui-kit/button/button';
import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';
import './address-card.style.scss';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const AddressCard: FC<AddressCardProps> = ({
    defaultAddress,
    address1,
    address2,
    city,
    state,
    zipcode,
    zipcodeFour,
    openDeleteModal,
    openEdit,
    isChecked,
    index,
    onChange,
    defaultAddressLabel,
    addressType,
    isProfile,
    showLabel = true
}: AddressCardProps): ReactElement => {
    const { t } = useTranslation();
    const formatAddressType = (addressType: string) => {
        return addressType
            .split('')
            .map((char, i) => (i === 0 ? char : char.toLowerCase()))
            .join('');
    };
    return (
        <Col className="mb-4 flex-fill">
            <div className="address-card d-flex flex-column p-4 border rounded rounded-2 border-sea-foam h-100">
                <h6 className="d-flex address-card__eyebrow mb-2">
                    {addressType && showLabel && (
                        <p className="address-card__type text-left mb-0">
                            <small>
                                {t('pages.profile.shippingAddress.addressType', {
                                    type: formatAddressType(addressType)
                                })}
                            </small>
                        </p>
                    )}
                    <div className="address-card__actions">
                        <Button
                            className="address-card__action"
                            variant="text"
                            type="button"
                            onClick={openEdit}
                            dataGAFormName="address-card"
                            label={t('shipping.editAddress')}
                        />
                        {isProfile && !defaultAddress && (
                            <>
                                <span className="divider">|</span>
                                <Button
                                    className="address-card__action"
                                    variant="text"
                                    type="button"
                                    onClick={openDeleteModal}
                                    dataGAFormName="address-card"
                                    label={t('shipping.removeAddress')}
                                />
                            </>
                        )}
                    </div>
                </h6>
                <Row className="address-card--content h-100">
                    <Col xs={12} className={'align-self-start'}>
                        <h6 className="hero-eyebrow-text">
                            {address1} {address2 ? address2 : ''}
                        </h6>
                        <h6 className="hero-eyebrow-text">
                            {city}, {state} {zipcodeFour ? `${zipcode}-${zipcodeFour}` : zipcode}
                        </h6>
                    </Col>
                    <Col xs={12} className={'align-self-end'}>
                        <section className="address-card--default-address mt-4">
                            <label className="default-address d-flex mb-0">
                                <input
                                    type="radio"
                                    name="option"
                                    id={`address-${index}`}
                                    onChange={onChange}
                                    checked={defaultAddress}
                                />
                                <span>{defaultAddressLabel}</span>
                            </label>
                        </section>
                    </Col>
                </Row>
            </div>
        </Col>
    );
};

export default AddressCard;
